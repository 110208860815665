const Flickity = require("flickity");
require("flickity-imagesloaded");

const Cookies = require("js-cookie");
import AOS from "aos";

(function ($) {
  const gooiland = {};

  gooiland.cookiewarning = function () {
    $(".cookie-warning").each(function () {
      let cookieStatus = Cookies.get("cookieWarning"),
        $el = $(this);
      if (cookieStatus !== "accepted") {
        $el.addClass("active");
      }

      $el.on("click", ".cookie-warning__accept", function (e) {
        Cookies.set("cookieWarning", "accepted", { expires: 365 });
        $el.removeClass("active");
        e.preventDefault();
      });
    });
  };

  gooiland.menu = function () {
    $(".nav-bar .hamburger").on("click", function () {
      $("body").toggleClass("menu-active");

      $(this).toggleClass("is-active");
      $(".mobilenav-collapse").toggleClass("mobilenav--active");

      $("#menu-nav-bar-1")
        .find(".sub-menu--active")
        .removeClass("sub-menu--active");
    });
  };

  gooiland.subMenu = function () {
    $("#menu-nav-bar-1")
      .find(".menu-item-has-children")
      .find("a:first")
      .on("click", function (e) {
        e.preventDefault();
        if (e.target != this) return;

        $(this)
          .closest(".menu-item-has-children")
          .find(".sub-menu")
          .addClass("sub-menu--active");
      });

    $("#menu-nav-bar-1")
      .find(".back-btn")
      .on("click", function (e) {
        e.preventDefault();
        $(this).closest(".sub-menu").removeClass("sub-menu--active");
        e.stopPropagation();
      });
  };

  gooiland.imgCaption_carousel = function () {
    let $singleImgCarousel = $(".singleImg-carousel");
    $singleImgCarousel.each(function (i, e) {
      new Flickity(e, {
        pageDots: false,
        draggable: true,
        wrapAround: true,
        cellAlign: "center",
        arrowShape: {
          x0: 20,
          x1: 55,
          y1: 35,
          x2: 60,
          y2: 30,
          x3: 30,
        },
      });
    });
  };

  gooiland.casesPreview_carousel = function () {
    let $casesCarousel = $(".module-cases__carousel");
    $casesCarousel.each(function (i, e) {
      new Flickity(e, {
        pageDots: false,
        prevNextButtons: true,
        draggable: true,
        wrapAround: true,
        cellAlign: "left",
        arrowShape: {
          x0: 20,
          x1: 55,
          y1: 35,
          x2: 60,
          y2: 30,
          x3: 30,
        },
      });
    });
  };

  gooiland.timeline_carousel = function () {
    let $timelineCarousel = $(".timeline-carousel");
    $timelineCarousel.each(function (i, e) {
      new Flickity(e, {
        pageDots: false,
        wrapAround: true,
        draggable: true,
        cellAlign: "left",
        arrowShape: {
          x0: 20,
          x1: 55,
          y1: 35,
          x2: 60,
          y2: 30,
          x3: 30,
        },
      });
    });
  };

  gooiland.agenda_carousel = function () {
    let $agendaCarousel = $(".agenda__carousel");
    $agendaCarousel.each(function (i, e) {
      new Flickity(e, {
        pageDots: false,
        wrapAround: false,
        draggable: true,
        cellAlign: "left",
        arrowShape: {
          x0: 20,
          x1: 55,
          y1: 35,
          x2: 60,
          y2: 30,
          x3: 30,
        },
      });
    });
  };

  gooiland.spacesPreview_carousel = function () {
    let $doubleImgCarousel = $(".module-spaces__carousel");
    $doubleImgCarousel.each(function (i, e) {
      new Flickity(e, {
        pageDots: false,
        draggable: true,
        wrapAround: true,
        groupCells: 2,
        cellAlign: "left",
        arrowShape: {
          x0: 20,
          x1: 55,
          y1: 35,
          x2: 60,
          y2: 30,
          x3: 30,
        },
      });
    });
  };

  gooiland.imgTxtblock_carousel = function () {
    let $eventsCarousel = $(".gallery-carousel");
    $eventsCarousel.each(function (i, e) {
      new Flickity(e, {
        pageDots: false,
        draggable: true,
        wrapAround: true,
        cellAlign: "left",
        arrowShape: {
          x0: 20,
          x1: 55,
          y1: 35,
          x2: 60,
          y2: 30,
          x3: 30,
        },
      });
    });
  };

  gooiland.zones_carousel = function () {
    let $eventsCarousel = $(".zone-carousel");
    $eventsCarousel.each(function (i, e) {
      new Flickity(e, {
        pageDots: false,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        watchCSS: true,
        cellAlign: "left",
        arrowShape: {
          x0: 20,
          x1: 55,
          y1: 35,
          x2: 60,
          y2: 30,
          x3: 30,
        },
      });
    });
  };

  gooiland.quotes_carousel = function () {
    let $quoteCarousel = $(".quotes-carousel");
    $quoteCarousel.each(function (i, e) {
      new Flickity(e, {
        pageDots: false,
        draggable: true,
        wrapAround: true,
        autoPlay: $(e).data('delay') || 10000,
        imagesLoaded: true,
        cellAlign: "center",
        pauseAutoPlayOnHover: false,
      });
    });
  };

  gooiland.navMenu_pageScroll = function () {
    $(document).on("click", ".js-pagejump", function (e) {
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        let target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          e.preventDefault();
          let targetOffset = target.offset().top;
          $("html, body").animate(
            {
              scrollTop: targetOffset - 200,
            },
            1000,
            function () {
              let $target = $(target);
              $target.focus();

              if ($target.is(":focus")) {
                return false;
              } else {
                $target.attr("tabindex", "-1");
                $target.focus();
              }
            }
          );
        }
      }
    });
  };

  gooiland.videoModule = function () {
    $(document).on("click", ".js_play-button", function (e) {
      e.preventDefault();
      let $button = $(e.target);
      let $parent = $button.closest(".js_video-parent");
      $parent.addClass("js_video-playing");
      let $video = $parent.find("video");
      $video[0].play();
    });

    $(document).on("click", ".js_video-playing", function (e) {
      e.preventDefault();
      let $parent = $(this);
      $parent.removeClass("js_video-playing");
      let $video = $parent.find("video");
      $video[0].pause();
    });
  };

  gooiland.init = function () {
    gooiland.cookiewarning();
    gooiland.casesPreview_carousel();
    gooiland.spacesPreview_carousel();
    gooiland.imgTxtblock_carousel();
    gooiland.imgCaption_carousel();
    gooiland.agenda_carousel();
    gooiland.zones_carousel();
    gooiland.quotes_carousel();
    gooiland.timeline_carousel();
    gooiland.navMenu_pageScroll();
    gooiland.menu();
    gooiland.subMenu();
    gooiland.videoModule();

    AOS.init();
  };

  gooiland.init();

  $(document).ready(function () {
    $("body").trigger("bless");

    if (typeof gform !== "undefined") {
      gform.addFilter(
        "gform_datepicker_options_pre_init",
        function (optionsObj) {
          optionsObj.changeMonth = false;
          optionsObj.changeYear = false;
          return optionsObj;
        }
      );
    }
  });

  $(document).bind("gform_post_render", function (event, form_id) {
    $("#gform_" + form_id)
      .find(".gfield_select")
      .selectize();
  });

  $(document).on("click", ".customsubmitbutton", function (e) {
    e.preventDefault();

    const form = $(this).closest("form")[0];

    if (form) {
      form.submit();
    }
  });
})(jQuery);
